<template>
	<div>
		<el-drawer v-if="!mobileInfo" title="查看产品信息" :wrapperClosable="false" :visible.sync="drawer_" destroy-on-close
			direction="rtl" custom-class="demo-drawer" ref="drawer" size="70%" @open="open">
			<div class="demo-drawer__content" style="width: 100%">
				<!-- 抽屉 -->
				<div class="edit" style="padding: 0 30px; width: 100%">
					<p>基本信息</p>

					<el-form :model="ruleForm" status-icon ref="ruleForm" style="width: 90%" class="demo-ruleForm"
						label-position="rigth" label-width="180px">
						<el-form-item label="产品名称:">
							<div>{{ dataObj.financialProductName }}</div>
						</el-form-item>
						<el-form-item label="产品简介:">
							<div class="info" v-html="fn(dataObj.productIntroduction || '')"></div>
						</el-form-item>
						<el-form-item label="担保方式:">
							<div>{{ dataObj.mortgageOrCreditName }}</div>
						</el-form-item>
						<el-form-item label="贷款主体:">
							<div>{{ dataObj.companyOrPersonalName }}</div>
						</el-form-item>
						<el-form-item label="申请方式:">
							<div>{{ dataObj.onlineOrOfflineName }}</div>
						</el-form-item>
						<el-form-item v-if="dataObj.mortgageOrCredit == 1">
							<span style="font-weight: 400" slot="label">抵押成数
								<el-tooltip effect="dark" placement="right">
									<div v-html="'1、足额是指住宅抵押率7成或以下；<br>2、高额是指住宅抵押率7-10成；<br>3、满额是指住宅抵押率10成；<br>4、超额是指住宅抵押率超过10成；<br>'
			" slot="content"></div>
									<i class="icon iconfont icon-wenhao"></i>
								</el-tooltip>:</span>
							<div>{{ dataObj.mortgageRateName }}</div>
						</el-form-item>
						<el-form-item label="产品标准化:">
							<div>{{ dataObj.standardName }}</div>
						</el-form-item>

						<el-form-item label="用途分类:">
							<div>{{ dataObj.useClassificationName }}</div>
						</el-form-item>
						<el-form-item label="产品类别:">
							<div>{{ dataObj.productCategoryName }}</div>
						</el-form-item>
						<el-form-item label="适用区域:">
							<div>{{ dataObj.applicableAreaName }}</div>
						</el-form-item>




						<p>主要参数</p>
						<el-form-item label="金额上限(万元):">
							<div>{{ ruleForm.amountOnline }}</div>
						</el-form-item>
						<el-form-item label="利率区间(%):">
							<div style="display: flex; align-items: center">
								<div>{{ ruleForm.interestRateLower }}</div>
								<div style="margin: 0 10px">至</div>
								<div>{{ ruleForm.interestRateUpper }}</div>
							</div>
						</el-form-item>
						<el-form-item label="最长期限(月):">
							<div style="display: flex; align-items: center">
								<div>{{ ruleForm.termMin }}</div>
								<div style="margin: 0 10px">至</div>
								<div>{{ ruleForm.term }}</div>
							</div>
						</el-form-item>

						<el-form-item label="还款方式:">
							<div>{{ ruleForm.productRepaymentMethodName }}</div>
						</el-form-item>
						<el-form-item label="是否每年过桥:" v-if="ruleForm.term > 12">
							<div>{{ ruleForm.crossBridgeName }}</div>
						</el-form-item>
						<el-form-item label="过桥描述:" v-if="ruleForm.crossBridge == 4 && ruleForm.term > 12">
							<div>{{ ruleForm.crossBridgeDesc }}</div>
						</el-form-item>
						<el-form-item label="额度类型:">
							<div>{{ ruleForm.quotaTypeName }}</div>
						</el-form-item>
						<el-form-item label="提款要求:">
							<div>{{ ruleForm.withdrawalRequirementName }}</div>
						</el-form-item>
						<el-form-item label="收款账户:">
							<div>{{ ruleForm.collectionAccountName }}</div>
						</el-form-item>
						<el-form-item label="抵押率(%):" v-if="ruleForm.mortgageOrCredit == 1 &&
			ruleForm.mortgageRate == 1
			">
							<div>{{ ruleForm.mortgageRatio }}</div>
						</el-form-item>
						<el-form-item v-if="(ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3)" label="线上扫码是否查征信:">
							<div>{{ ruleForm.checkCreditScanName }}</div>
						</el-form-item>
						<el-form-item label="备注:">
							<div class="info" v-html="fn(ruleForm.otherParameter || '')"></div>
						</el-form-item>
						<p>产品准入</p>
						<template v-if="dataObj.useClassification == 1">
							<span>企业维度</span>
							<el-form-item label="成立年限(月):">
								<div>{{ dataObj.incorporationYears }}</div>
							</el-form-item>
							<el-form-item label="所在省市:">
								<div>
									{{ dataObj.registeredPlaceName }}
								</div>
							</el-form-item>
							<el-form-item label="税务等级:" v-if="dataObj.companyOrPersonal == 1 &&
			(dataObj.onlineOrOffline == 1 || dataObj.onlineOrOffline == 3)
			">
								<div>{{ dataObj.taxClassificationName }}</div>
							</el-form-item>
							<el-form-item v-if="dataObj.companyOrPersonal == 1 && dataObj.mortgageOrCredit == 2" label="上年主营收入(万元):">

								<div>{{ dataObj.lastYearInvoiceAmount }}</div>
							</el-form-item>

							<el-form-item label="征信要求:">
								<div>
									{{ dataObj.enterpriseCreditRequirements }}
								</div>
							</el-form-item>

							<el-form-item label="备注:">
								<div class="info" v-html="fn(dataObj.otherEnterprise || '')"></div>
							</el-form-item>
						</template>
						<template v-if="dataObj.companyOrPersonal == 1">
							<span>法人维度</span>
							<div class="requirement" v-if="ruleForm.noWriteActualController">
								<img src="../../../assets/hint.png" alt="" />
								<span>本产品对实控人信息无要求</span>
							</div>
							<div v-else>
								<el-form-item label="年龄限制(岁):">
									<div>{{ dataObj.ageLimit }}</div>
								</el-form-item>
								<el-form-item label="国籍限制:">
									<div>
										{{ dataObj.internationalRestrictionsName }}
									</div>
								</el-form-item>
								<el-form-item label="房产要求:">
									<div>
										{{ dataObj.propertyRequirementActualControllerName }}
									</div>
								</el-form-item>
								<el-form-item label="征信要求:">
									<div class="info" v-html="fn(dataObj.creditRequirements || '')
			"></div>
								</el-form-item>
								<el-form-item label="配偶征信:">
									<div class="info" v-html="fn(
			dataObj.spouseCreditRequirements ||
			''
		)
			"></div>
								</el-form-item>
								<el-form-item label="备注:">
									<div class="info" v-html="fn(dataObj.otherActualController || '')
			"></div>
								</el-form-item>
							</div>
						</template>
						<template v-if="ruleForm.companyOrPersonal == 2">
							<span>借款人维度</span>

							<div class="requirement" v-if="ruleForm.noWriteBorrower">
								<img src="../../../assets/hint.png" alt="" />
								<span>本产品对借款人信息无要求</span>
							</div>
							<div v-else>
								<el-form-item label="年龄限制(岁):">
									<div>{{ ruleForm.ageLimitBorrower }}</div>
								</el-form-item>
								<el-form-item label="国籍限制:">
									<div>
										{{ ruleForm.internationalRestrictionsBorrowerName }}
									</div>
								</el-form-item>
								<el-form-item v-if="ruleForm.mortgageOrCredit == 2" label="房产要求:">
									<div>
										{{ ruleForm.propertyRequirementsName }}
									</div>
								</el-form-item>
								<el-form-item v-if="ruleForm.mortgageOrCredit == 2" label="职业要求:">
									<div>
										{{ ruleForm.professionalRequirementsName }}
									</div>
								</el-form-item>
								<el-form-item v-if="ruleForm.useClassification == 1" label="持股比例(%):">
									<div>{{ ruleForm.shareholdingRatio }}</div>
								</el-form-item>
								<el-form-item v-if="ruleForm.useClassification == 1" label="持股时间(月):">
									<div>{{ ruleForm.holdingPeriod }}</div>
								</el-form-item>
								<el-form-item label="征信要求:">
									<div class="info" v-html="fn(
			ruleForm.creditRequirementsBorrower ||
			''
		)
			"></div>
								</el-form-item>
								<el-form-item label="配偶征信:">
									<div class="info" v-html="fn(
			ruleForm.spouseCreditRequirementsBorrower ||
			''
		)
			"></div>
								</el-form-item>

								<el-form-item label="流水要求:">
									<div class="info" v-html="fn(ruleForm.flowRequirements || '')"></div>
								</el-form-item>
								<el-form-item label="备注:">
									<div class="info" v-html="fn(ruleForm.otherBorrower || '')"></div>
								</el-form-item>
							</div>
						</template>
						<template v-if="ruleForm.mortgageOrCredit == 1">
							<span>抵押物要求</span>
							<el-form-item label="抵押物类型:">
								<div>{{ ruleForm.collateralTypesName }}</div>
							</el-form-item>
							<el-form-item>
								<span style="
									font-weight: 400;
									position: relative;
									z-index: 1;
								" slot="label">抵押人关系
									<el-tooltip effect="dark" placement="right">
										<span v-html="dyrhtml" slot="content"></span>
										<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
								<div>{{ ruleForm.mortgagorName }}</div>
							</el-form-item>
							<el-form-item label="抵押物省市:">
								<div>
									{{ ruleForm.mortgageLocationName }}
								</div>
							</el-form-item>
							<el-form-item label="抵押人年龄（岁）:">
								<div>
									{{ ruleForm.ageOfMortgagor }}
								</div>
							</el-form-item>
							<el-form-item label="抵押物楼龄（年）:" v-if="ruleForm.companyOrPersonal == 2 &&
			ruleForm.mortgageOrCredit == 1
			">
								<div>{{ ruleForm.mortgageAge }}</div>
							</el-form-item>
							<el-form-item label="过户时间(月):">
								<div>{{ ruleForm.transferTime }}</div>
							</el-form-item>

							<el-form-item label="备注:">
								<div class="info" v-html="fn(ruleForm.otherMortgage || '')"></div>
							</el-form-item>
						</template>

						<span>其他要求</span>
						<div class="requirement" v-if="ruleForm.noWriteOtherElements">
							<img src="../../../assets/hint.png" alt="" />
							<span>本产品对借款人信息无要求</span>
						</div>
						<div v-else>
							<el-form-item label="其他要求:">
								<div class="info" v-html="fn(ruleForm.otherCoreAccess || '')"></div>
							</el-form-item>
						</div>
						<p>禁止准入</p>
						<el-form-item label="贷款银行家数限制(家):">
							<div>{{ ruleForm.loanBankNumberLimit }}</div>
						</el-form-item>
						<el-form-item v-if="(ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3)"
							label="法人变更时间不小于(月) :">
							<div>{{ ruleForm.corporateChangeTimeNotLessThan }}</div>

						</el-form-item>
						<el-form-item label="征信白户可否准入:"
							v-if="(ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3) && ruleForm.mortgageOrCredit == 2">

							<div>{{ ruleForm.whetherWhiteHouseholdsAdmittedName }}</div>

						</el-form-item>
						<el-form-item label="上年度亏损可否准入:"
							v-if="(ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3) && ruleForm.mortgageOrCredit == 2">
							<div>{{ ruleForm.previousYearsLossesAllowedForAdmissionName }}</div>

						</el-form-item>
						<el-form-item label="有小贷余额可否准入:"
							v-if="(ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3) && ruleForm.mortgageOrCredit == 2">

							<div>{{ ruleForm.microloanBalanceAllowedForAdmissionName }}</div>

						</el-form-item>
						<el-form-item label="补充说明:">
							<div class="info" v-html="fn(ruleForm.prohibitionAccess || '')"></div>
						</el-form-item>

						<p>额度计算</p>
						<el-form-item class="mb" :label="index == 0 ? '额度计算:' : ''" v-for="(
							item, index
						) in ruleForm.quotaAccountingCoreLogic" :key="'C' + index">
							<div style="display: flex; align-items: center">
								<div>{{ item.value }}</div>
							</div>
						</el-form-item>
						<el-form-item class="mb" :label="index == 0 ? '风控要点:' : ''" v-for="(
							item, index
						) in ruleForm.basicRiskControlMeasures" :key="'G' + index">
							<div style="display: flex; align-items: center">
								<div>{{ item.value }}</div>
							</div>
						</el-form-item>
						<el-form-item label="补充说明:">
							<div class="info" v-html="fn(ruleForm.supplementaryStatement || '')"></div>
						</el-form-item>
						<p>专家解读</p>
						<el-form-item class="mb" :label="index == 0 ? '核心优势:' : ''" v-for="(item, index) in ruleForm.maiAdvantages"
							:key="'K' + index">
							<div style="display: flex; align-items: center">
								<div>{{ item.value }}</div>
							</div>
						</el-form-item>
						<el-form-item class="mb" :label="index == 0 ? '产品劣势:' : ''"
							v-for="(item, index) in ruleForm.mainDisadvantages" :key="'L' + index">
							<div style="display: flex; align-items: center">
								<div>{{ item.value }}</div>
							</div>
						</el-form-item>
						<el-form-item class="mb" :label="index == 0 ? '适客人群:' : ''"
							v-for="(item, index) in ruleForm.suitableCustomers" :key="'Z' + index">
							<div style="display: flex; align-items: center">
								<div>{{ item.value }}</div>
							</div>
						</el-form-item>
						<p>产品附件</p>
						<el-form-item label="资料清单附件:">
							<el-upload disabled :headers="{
			token: $store.state.userInfo.token,
		}" class="upload-demo" name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
								:on-preview="handelOnChange" multiple :limit="3" :file-list="ruleForm.informationListAttachment">
							</el-upload>
						</el-form-item>
						<el-form-item label="产品说明书附件:">
							<el-upload :on-preview="handelOnChange" :headers="{
			token: $store.state.userInfo.token,
		}" class="upload-demo" name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`" multiple
								:limit="3" :file-list="ruleForm.productInstructionAttachment">
							</el-upload>
						</el-form-item>
						<el-form-item label="其它附件:">
							<el-upload :headers="{
			token: $store.state.userInfo.token,
		}" :on-preview="handelOnChange" class="upload-demo" name="files"
								:action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`" multiple :limit="3"
								:file-list="ruleForm.otherAttachment">
							</el-upload>
						</el-form-item>

					</el-form>

					<div class="demo-drawer__footer">
						<el-button @click="$emit('close')">关 闭</el-button>
					</div>
				</div>
			</div>
		</el-drawer>
		<template v-else>
			<div class="edit" style="width: 100%">
				<p><i class="icon iconfont icon-productManagement" style="margin-right: 5px; font-size: 14px;"></i>基本信息</p>

				<el-form :model="ruleForm" status-icon ref="ruleForm" class="demo-ruleForm" label-position="rigth">
					<el-form-item label="产品名称:">
						<div>{{ ruleForm.financialProductName }}</div>
					</el-form-item>
					<el-form-item label="产品简介:">
						<div class="info" v-html="fn(ruleForm.productIntroduction || '')"></div>
					</el-form-item>
					<el-form-item label="担保方式:">
						<div>{{ ruleForm.mortgageOrCreditName }}</div>
					</el-form-item>
					<el-form-item label="贷款主体:">
						<div>{{ ruleForm.companyOrPersonalName }}</div>
					</el-form-item>
					<el-form-item label="申请方式:">
						<div>{{ ruleForm.onlineOrOfflineName }}</div>
					</el-form-item>
					<el-form-item v-if="ruleForm.mortgageOrCredit == 1">
						<span style="font-weight: 400" slot="label">抵押成数
							<el-tooltip effect="dark" placement="right">
								<div v-html="'1、足额是指住宅抵押率7成或以下；<br>2、高额是指住宅抵押率7-10成；<br>3、满额是指住宅抵押率10成；<br>4、超额是指住宅抵押率超过10成；<br>'
			" slot="content"></div>
								<i class="icon iconfont icon-wenhao"></i>
							</el-tooltip>:</span>
						<div>{{ ruleForm.mortgageRateName }}</div>
					</el-form-item>
					<el-form-item label="产品标准化:">
						<div>{{ ruleForm.standardName }}</div>
					</el-form-item>

					<el-form-item label="用途分类:">
						<div>{{ ruleForm.useClassificationName }}</div>
					</el-form-item>
					<el-form-item label="产品类别:">
						<div>{{ ruleForm.productCategoryName }}</div>
					</el-form-item>
					<el-form-item label="适用区域:">
						<div>{{ ruleForm.applicableAreaName }}</div>
					</el-form-item>




					<p><i class="icon iconfont icon-productManagement" style="margin-right: 5px; font-size: 14px;"></i>主要参数</p>
					<el-form-item label="金额上限(万元):">
						<div>{{ ruleForm.amountOnline }}</div>
					</el-form-item>
					<el-form-item label="利率区间(%):">
						<div style="display: flex; align-items: center">
							<div>{{ ruleForm.interestRateLower }}</div>
							<div style="margin: 0 10px">至</div>
							<div>{{ ruleForm.interestRateUpper }}</div>
						</div>
					</el-form-item>
					<el-form-item label="最长期限(月):">
						<div style="display: flex; align-items: center">
							<div>{{ ruleForm.termMin }}</div>
							<div style="margin: 0 10px">至</div>
							<div>{{ ruleForm.term }}</div>
						</div>
					</el-form-item>

					<el-form-item label="还款方式:">
						<div>{{ ruleForm.productRepaymentMethodName }}</div>
					</el-form-item>
					<el-form-item label="是否每年过桥:" v-if="ruleForm.term > 12">
						<div>{{ ruleForm.crossBridgeName }}</div>
					</el-form-item>
					<el-form-item label="过桥描述:" v-if="ruleForm.crossBridge == 4 && ruleForm.term > 12">
						<div>{{ ruleForm.crossBridgeDesc }}</div>
					</el-form-item>
					<el-form-item label="额度类型:">
						<div>{{ ruleForm.quotaTypeName }}</div>
					</el-form-item>
					<el-form-item label="提款要求:">
						<div>{{ ruleForm.withdrawalRequirementName }}</div>
					</el-form-item>
					<el-form-item label="收款账户:">
						<div>{{ ruleForm.collectionAccountName }}</div>
					</el-form-item>
					<el-form-item label="抵押率(%):" v-if="ruleForm.mortgageOrCredit == 1 &&
			ruleForm.mortgageRate == 1
			">
						<div>{{ ruleForm.mortgageRatio }}</div>
					</el-form-item>
					<el-form-item v-if="(ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3)" label="线上扫码是否查征信:">
						<div>{{ ruleForm.checkCreditScanName }}</div>
					</el-form-item>
					<el-form-item label="备注:">
						<div class="info" v-html="fn(ruleForm.otherParameter || '')"></div>
					</el-form-item>
					<p><i class="icon iconfont icon-productManagement" style="margin-right: 5px; font-size: 14px;"></i>产品准入</p>
					<template v-if="ruleForm.useClassification == 1">
						<el-divider content-position="left">企业维度</el-divider>
						<el-form-item label="成立年限(月):">
							<div>{{ ruleForm.incorporationYears }}</div>
						</el-form-item>
						<el-form-item label="所在省市:">
							<div>
								{{ ruleForm.registeredPlaceName }}
							</div>
						</el-form-item>
						<el-form-item label="税务等级:" v-if="ruleForm.companyOrPersonal == 1 &&
			(ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3)
			">
							<div>{{ ruleForm.taxClassificationName }}</div>
						</el-form-item>
						<el-form-item v-if="ruleForm.companyOrPersonal == 1 && ruleForm.mortgageOrCredit == 2" label="上年主营收入(万元):">

							<div>{{ ruleForm.lastYearInvoiceAmount }}</div>
						</el-form-item>

						<el-form-item label="征信要求:">
							<div>
								{{ ruleForm.enterpriseCreditRequirements }}
							</div>
						</el-form-item>

						<el-form-item label="备注:">
							<div class="info" v-html="fn(ruleForm.otherEnterprise || '')"></div>
						</el-form-item>
					</template>
					<template v-if="ruleForm.companyOrPersonal == 1">
						<el-divider content-position="left">法人维度</el-divider>

						<div class="requirement" v-if="ruleForm.noWriteActualController">
							<img src="../../../assets/hint.png" alt="" />
							<span>本产品对实控人信息无要求</span>
						</div>
						<div v-else>
							<el-form-item label="年龄限制(岁):">
								<div>{{ ruleForm.ageLimit }}</div>
							</el-form-item>
							<el-form-item label="国籍限制:">
								<div>
									{{ ruleForm.internationalRestrictionsName }}
								</div>
							</el-form-item>
							<el-form-item label="房产要求:">
								<div>
									{{ ruleForm.propertyRequirementActualControllerName }}
								</div>
							</el-form-item>
							<el-form-item label="征信要求:">
								<div class="info" v-html="fn(ruleForm.creditRequirements || '')
			"></div>
							</el-form-item>
							<el-form-item label="配偶征信:">
								<div class="info" v-html="fn(
			ruleForm.spouseCreditRequirements ||
			''
		)
			"></div>
							</el-form-item>
							<el-form-item label="备注:">
								<div class="info" v-html="fn(ruleForm.otherActualController || '')
			"></div>
							</el-form-item>
						</div>
					</template>
					<template v-if="ruleForm.companyOrPersonal == 2">
						<el-divider content-position="left">借款人维度</el-divider>

						<div class="requirement" v-if="ruleForm.noWriteBorrower">
							<img src="../../../assets/hint.png" alt="" />
							<span>本产品对借款人信息无要求</span>
						</div>
						<div v-else>
							<el-form-item label="年龄限制(岁):">
								<div>{{ ruleForm.ageLimitBorrower }}</div>
							</el-form-item>
							<el-form-item label="国籍限制:">
								<div>
									{{ ruleForm.internationalRestrictionsBorrowerName }}
								</div>
							</el-form-item>
							<el-form-item v-if="ruleForm.mortgageOrCredit == 2" label="房产要求:">
								<div>
									{{ ruleForm.propertyRequirementsName }}
								</div>
							</el-form-item>
							<el-form-item v-if="ruleForm.mortgageOrCredit == 2" label="职业要求:">
								<div>
									{{ ruleForm.professionalRequirementsName }}
								</div>
							</el-form-item>
							<el-form-item v-if="ruleForm.useClassification == 1" label="持股比例(%):">
								<div>{{ ruleForm.shareholdingRatio }}</div>
							</el-form-item>
							<el-form-item v-if="ruleForm.useClassification == 1" label="持股时间(月):">
								<div>{{ ruleForm.holdingPeriod }}</div>
							</el-form-item>
							<el-form-item label="征信要求:">
								<div class="info" v-html="fn(
			ruleForm.creditRequirementsBorrower ||
			''
		)
			"></div>
							</el-form-item>
							<el-form-item label="配偶征信:">
								<div class="info" v-html="fn(
			ruleForm.spouseCreditRequirementsBorrower ||
			''
		)
			"></div>
							</el-form-item>

							<el-form-item label="流水要求:">
								<div class="info" v-html="fn(ruleForm.flowRequirements || '')"></div>
							</el-form-item>
							<el-form-item label="备注:">
								<div class="info" v-html="fn(ruleForm.otherBorrower || '')"></div>
							</el-form-item>
						</div>
					</template>
					<template v-if="ruleForm.mortgageOrCredit == 1">
						<el-divider content-position="left">抵押物要求</el-divider>

						<el-form-item label="抵押物类型:">
							<div>{{ ruleForm.collateralTypesName }}</div>
						</el-form-item>
						<el-form-item>
							<span style="
									font-weight: 400;
									position: relative;
									z-index: 1;
								" slot="label">抵押人关系
								<el-tooltip effect="dark" placement="right">
									<span v-html="dyrhtml" slot="content"></span>
									<i class="icon iconfont icon-wenhao"></i> </el-tooltip>:</span>
							<div>{{ ruleForm.mortgagorName }}</div>
						</el-form-item>
						<el-form-item label="抵押物省市:">
							<div>
								{{ ruleForm.mortgageLocationName }}
							</div>
						</el-form-item>
						<el-form-item label="抵押人年龄（岁）:">
							<div>
								{{ ruleForm.ageOfMortgagor }}
							</div>
						</el-form-item>
						<el-form-item label="抵押物楼龄（年）:" v-if="ruleForm.companyOrPersonal == 2 &&
			ruleForm.mortgageOrCredit == 1
			">
							<div>{{ ruleForm.mortgageAge }}</div>
						</el-form-item>
						<el-form-item label="过户时间(月):">
							<div>{{ ruleForm.transferTime }}</div>
						</el-form-item>

						<el-form-item label="备注:">
							<div class="info" v-html="fn(ruleForm.otherMortgage || '')"></div>
						</el-form-item>
					</template>

					<el-divider content-position="left">其他要求</el-divider>

					<div class="requirement" v-if="ruleForm.noWriteOtherElements">
						<img src="../../../assets/hint.png" alt="" />
						<span>本产品对借款人信息无要求</span>
					</div>
					<div v-else>
						<el-form-item label="其他要求:">
							<div class="info" v-html="fn(ruleForm.otherCoreAccess || '')"></div>
						</el-form-item>
					</div>
					<p><i class="icon iconfont icon-productManagement" style="margin-right: 5px; font-size: 14px;"></i>禁止准入</p>
					<el-form-item label="贷款银行家数限制(家):">
						<div>{{ ruleForm.loanBankNumberLimit }}</div>
					</el-form-item>
					<el-form-item v-if="(ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3)" label="法人变更时间不小于(月) :">
						<div>{{ ruleForm.corporateChangeTimeNotLessThan }}</div>

					</el-form-item>
					<el-form-item label="征信白户可否准入:"
						v-if="(ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3) && ruleForm.mortgageOrCredit == 2">

						<div>{{ ruleForm.whetherWhiteHouseholdsAdmittedName }}</div>

					</el-form-item>
					<el-form-item label="上年度亏损可否准入:"
						v-if="(ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3) && ruleForm.mortgageOrCredit == 2">
						<div>{{ ruleForm.previousYearsLossesAllowedForAdmissionName }}</div>

					</el-form-item>
					<el-form-item label="有小贷余额可否准入:"
						v-if="(ruleForm.onlineOrOffline == 1 || ruleForm.onlineOrOffline == 3) && ruleForm.mortgageOrCredit == 2">

						<div>{{ ruleForm.microloanBalanceAllowedForAdmissionName }}</div>

					</el-form-item>
					<el-form-item label="补充说明:">
						<div class="info" v-html="fn(ruleForm.prohibitionAccess || '')"></div>
					</el-form-item>

					<p><i class="icon iconfont icon-productManagement" style="margin-right: 5px; font-size: 14px;"></i>额度计算</p>
					<el-form-item class="mb" :label="index == 0 ? '额度计算:' : ''" v-for="(
							item, index
						) in ruleForm.quotaAccountingCoreLogic" :key="'C' + index">
						<div style="display: flex; align-items: center">
							<div>{{ item.value }}</div>
						</div>
					</el-form-item>
					<el-form-item class="mb" :label="index == 0 ? '风控要点:' : ''" v-for="(
							item, index
						) in ruleForm.basicRiskControlMeasures" :key="'G' + index">
						<div style="display: flex; align-items: center">
							<div>{{ item.value }}</div>
						</div>
					</el-form-item>
					<el-form-item label="补充说明:">
						<div class="info" v-html="fn(ruleForm.supplementaryStatement || '')"></div>
					</el-form-item>
					<p><i class="icon iconfont icon-productManagement" style="margin-right: 5px; font-size: 14px;"></i>专家解读</p>
					<el-form-item class="mb" :label="index == 0 ? '核心优势:' : ''" v-for="(item, index) in ruleForm.maiAdvantages"
						:key="'K' + index">
						<div style="display: flex; align-items: center">
							<div>{{ item.value }}</div>
						</div>
					</el-form-item>
					<el-form-item class="mb" :label="index == 0 ? '产品劣势:' : ''"
						v-for="(item, index) in ruleForm.mainDisadvantages" :key="'L' + index">
						<div style="display: flex; align-items: center">
							<div>{{ item.value }}</div>
						</div>
					</el-form-item>
					<el-form-item class="mb" :label="index == 0 ? '适客人群:' : ''"
						v-for="(item, index) in ruleForm.suitableCustomers" :key="'Z' + index">
						<div style="display: flex; align-items: center">
							<div>{{ item.value }}</div>
						</div>
					</el-form-item>
					<p><i class="icon iconfont icon-productManagement" style="margin-right: 5px; font-size: 14px;"></i>产品附件</p>
					<el-form-item label="资料清单附件:">
						<el-upload disabled :headers="{
			token: $store.state.userInfo.token,
		}" class="upload-demo" name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
							:on-preview="handelOnChange" multiple :limit="3" :file-list="ruleForm.informationListAttachment">
						</el-upload>
					</el-form-item>
					<el-form-item label="产品说明书附件:">
						<el-upload :on-preview="handelOnChange" :headers="{
			token: $store.state.userInfo.token,
		}" class="upload-demo" name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`" multiple
							:limit="3" :file-list="ruleForm.productInstructionAttachment">
						</el-upload>
					</el-form-item>
					<el-form-item label="其它附件:">
						<el-upload :headers="{
			token: $store.state.userInfo.token,
		}" :on-preview="handelOnChange" class="upload-demo" name="files"
							:action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`" multiple :limit="3"
							:file-list="ruleForm.otherAttachment">
						</el-upload>
					</el-form-item>

				</el-form>

				<div class="demo-drawer__footer">
					<el-button @click="$emit('close')">关 闭</el-button>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { readFinancialProduct } from '@/api/index.js';
export default {
	name: 'examineDrawer',
	props: {
		examineVisible: {
			type: Boolean,
			required: true,
		},
		dataObj: {
			type: Object,
			required: true,
		},
		mobileInfo: {
			type: Boolean,
			default: false
		},
	},
	data() {

		return {

			dyrhtml: `<b>1类关系人</b>
<p style='padding-left:10px'>1、法定代表人、2、公司股东、3、法人及股东直系亲属、6、本公司或管理公司名下资</p>
<b>2类关系人</b>
<p style='padding-left:10px'>4、实际控制人、5、实际控制人直系亲属</p>
<b>3类关系人</b>
<p style='padding-left:10px'>7、法人/股东/实控人非直系亲属</p>
<b>4类关系人</b>
<p style='padding-left:10px'>8、本公司员工及其直系亲属名下资产</p>
<b>5类关系人</b>
<p style='padding-left:10px'>9、其他第三方</p>`,
			ruleForm: {
				financialProductName: '',
				applicableAreaId: '1964',
				applicableAreaName: '深圳市',
				companyOrPersonal: '',
				onlineOrOffline: '',
				mortgageOrCredit: '',
				mortgageRate: '',
				standard: '',
				useClassification: '',
				incorporationYears: '',
				registeredPlaceId: '1964',
				registeredPlaceName: '深圳市',
				enterpriseOperation: '',
				enterpriseCreditRequirements: '',
				taxClassification: '',
				otherEnterprise: '',
				internationalRestrictions: [],
				ageLimit: '',
				creditRequirements: '',
				spouseCreditRequirements: '',
				otherActualController: '',
				internationalRestrictionsBorrower: [],
				ageLimitBorrower: '',
				creditRequirementsBorrower: '',
				spouseCreditRequirementsBorrower: '',
				shareholdingRatio: '',
				holdingPeriod: '',
				flowRequirements: '',
				propertyRequirements: '',
				professionalRequirements: [],
				otherBorrower: '',
				collateralTypes: [1],
				mortgagor: [],
				mortgageLocationId: '1964',
				mortgageLocationName: '深圳市',
				transferTime: '',
				mortgageAge: '',
				otherMortgage: '',
				otherCoreAccess: '',
				prohibitionAccess: '',
				quotaAccountingCoreLogic: [{ value: '' }],
				quotaAccountingSupplementaryLogic: [{ value: '' }],
				mortgageFocusIndicators: [{ value: '' }],
				creditFocusIndicators: [{ value: '' }],
				otherFocusIndicators: [{ value: '' }],
				amountOnline: '',
				interestRateLower: '',
				interestRateUpper: '',
				term: '',
				repaymentMethod: '',
				quotaTypeName: '',
				mortgageRatio: '',
				collectionAccount: '',
				crossBridge: '',
				otherParameter: '',
				basicRiskControlMeasures: [{ value: '' }],
				commonRiskControlMeasures: [{ value: '' }],
				focusRiskPoints: [{ value: '' }],
				maiAdvantages: [{ value: '' }],
				mainDisadvantages: [{ value: '' }],
				suitableCustomers: [{ value: '' }],
				informationListAttachment: [],
				productInstructionAttachment: [],
				otherAttachment: [],
				remark: '',
			},
			ruleForms: {},
		};
	},
	methods: {
		fn(str = '') {
			console.log(str, '--------');
			return str.replace(/\n/g, '<br />');
		},
		handelOnChange(res) {
			if (res.filePath) {
				res.url = res.filePath
			}
			if (res.url.includes('png') || res.url.includes('jpg') || res.url.includes('bmp') || res.url.includes('jpeg') || res.url.includes('xlsx') || res.url.includes('docx') || res.url.includes('pdf')) {
				let routeData = this.$router.resolve({ path: '/public', query: { url: res.url } });

				window.open(routeData.href, '_blank');
				return;
			}

			location.href =
				this.$baseUrl +
				'api/file/fileDownloadCommon.do?path=' +
				res.url;
		},
		async open() {
			const res = await readFinancialProduct({
				financialProductId: this.dataObj.financialProductId,
			});
			this.ruleForm = res.data;
			this.ruleForm.financialProductName =
				res.data.financialProductName || '';
			this.ruleForm.applicableAreaId =
				res.data.applicableAreaId || '1964';
			this.ruleForm.applicableAreaName =
				res.data.applicableAreaName || '深圳市';
			this.ruleForm.companyOrPersonal = res.data.companyOrPersonal || '';
			this.ruleForm.onlineOrOffline = res.data.onlineOrOffline || '';
			this.ruleForm.mortgageOrCredit = res.data.mortgageOrCredit || '';
			this.ruleForm.mortgageRate = res.data.mortgageRate || '';
			this.ruleForm.standard = res.data.standard || '';
			this.ruleForm.useClassification = res.data.useClassification || '';
			this.ruleForm.incorporationYears =
				res.data.incorporationYears || '';
			this.ruleForm.registeredPlaceId =
				res.data.registeredPlaceId || '1964';
			this.ruleForm.registeredPlaceName =
				res.data.registeredPlaceName || '深圳市';
			this.ruleForm.enterpriseOperation =
				res.data.enterpriseOperation || '';
			this.ruleForm.enterpriseCreditRequirements =
				res.data.enterpriseCreditRequirements || '';
			this.ruleForm.taxClassification = res.data.taxClassification || '';
			this.ruleForm.otherEnterprise = res.data.otherEnterprise || '';
			this.ruleForm.ageLimit = res.data.ageLimit || '';
			this.ruleForm.creditRequirements =
				res.data.creditRequirements || '';
			this.ruleForm.spouseCreditRequirements =
				res.data.spouseCreditRequirements || '';
			this.ruleForm.otherActualController =
				res.data.otherActualController || '';
			this.ruleForm.ageLimitBorrower = res.data.ageLimitBorrower || '';
			this.ruleForm.creditRequirementsBorrower =
				res.data.creditRequirementsBorrower || '';
			this.ruleForm.spouseCreditRequirementsBorrower =
				res.data.spouseCreditRequirementsBorrower || '';
			this.ruleForm.shareholdingRatio = res.data.shareholdingRatio || '';
			this.ruleForm.holdingPeriod = res.data.holdingPeriod || '';
			this.ruleForm.flowRequirements = res.data.flowRequirements || '';
			this.ruleForm.propertyRequirements =
				res.data.propertyRequirements || '';
			this.ruleForm.otherBorrower = res.data.otherBorrower || '';
			this.ruleForm.mortgageLocationId =
				res.data.mortgageLocationId || '1964';
			this.ruleForm.mortgageLocationName =
				res.data.mortgageLocationName || '深圳市';
			this.ruleForm.transferTime = res.data.transferTime || '';
			this.ruleForm.mortgageAge = res.data.mortgageAge || '';
			this.ruleForm.otherMortgage = res.data.otherMortgage || '';
			this.ruleForm.amountOnline = res.data.amountOnline || '';
			this.ruleForm.interestRateLower = res.data.interestRateLower || '';
			this.ruleForm.interestRateUpper = res.data.interestRateUpper || '';
			this.ruleForm.term = res.data.term || '';
			this.ruleForm.repaymentMethod = res.data.repaymentMethod || '';
			this.ruleForm.quotaTypeName = res.data.quotaTypeName || '';
			this.ruleForm.mortgageRatio = res.data.mortgageRatio || '';
			this.ruleForm.collectionAccount = res.data.collectionAccount || '';
			this.ruleForm.crossBridge = res.data.crossBridge || '';
			this.ruleForm.otherParameter = res.data.otherParameter || '';
			this.ruleForm.remark = res.data.remark || '';
			if (res.data.collateralTypes) {
				this.ruleForm.collateralTypes = res.data.collateralTypes
					.split(',')
					.map((item) => Number(item));
			} else {
				this.ruleForm.collateralTypes = [1];
			}

			if (res.data.internationalRestrictionsBorrower) {
				this.ruleForm.internationalRestrictionsBorrower =
					res.data.internationalRestrictionsBorrower
						.split(',')
						.map((item) => Number(item));
			} else {
				this.ruleForm.internationalRestrictionsBorrower = [];
			}
			if (res.data.professionalRequirements) {
				this.ruleForm.professionalRequirements =
					res.data.professionalRequirements
						.split(',')
						.map((item) => Number(item));
			} else {
				this.ruleForm.professionalRequirements = [];
			}

			if (res.data.mortgagor) {
				this.ruleForm.mortgagor = res.data.mortgagor
					.split(',')
					.map((item) => Number(item));
			} else {
				this.ruleForm.mortgagor = [];
			}

			if (res.data.internationalRestrictions) {
				this.ruleForm.internationalRestrictions =
					res.data.internationalRestrictions
						.split(',')
						.map((item) => Number(item));
			} else {
				this.ruleForm.internationalRestrictions = [];
			}

			if (res.data.informationListAttachment) {
				this.ruleForm.informationListAttachment = JSON.parse(
					res.data.informationListAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			} else {
				this.ruleForm.informationListAttachment = [];
			}

			if (res.data.productInstructionAttachment) {
				this.ruleForm.productInstructionAttachment = JSON.parse(
					res.data.productInstructionAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			} else {
				this.ruleForm.productInstructionAttachment = [];
			}

			if (res.data.otherAttachment) {
				this.ruleForm.otherAttachment = JSON.parse(
					res.data.otherAttachment
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			} else {
				this.ruleForm.otherAttachment = [];
			}

			this.ruleForm.otherCoreAccess = res.data.otherCoreAccess || ''

			this.ruleForm.quotaAccountingCoreLogic = res.list.reduce(
				(prev, cur) => {
					if (
						cur.informationProlect == 3 &&
						cur.informationCategory == 1
					) {
						prev.push({
							value: cur.informationContent,
						});
					}
					return prev;
				},
				[]
			);
			if (!this.ruleForm.quotaAccountingCoreLogic.length) {
				this.ruleForm.quotaAccountingCoreLogic.push({});
			}
			this.ruleForm.quotaAccountingSupplementaryLogic = res.list.reduce(
				(prev, cur) => {
					if (
						cur.informationProlect == 3 &&
						cur.informationCategory == 2
					) {
						prev.push({
							value: cur.informationContent,
						});
					}
					return prev;
				},
				[]
			);
			if (!this.ruleForm.quotaAccountingSupplementaryLogic.length) {
				this.ruleForm.quotaAccountingSupplementaryLogic.push({});
			}
			this.ruleForm.mortgageFocusIndicators = res.list.reduce(
				(prev, cur) => {
					if (
						cur.informationProlect == 4 &&
						cur.informationCategory == 1
					) {
						prev.push({
							value: cur.informationContent,
						});
					}
					return prev;
				},
				[]
			);
			if (!this.ruleForm.mortgageFocusIndicators.length) {
				this.ruleForm.mortgageFocusIndicators.push({});
			}
			this.ruleForm.creditFocusIndicators = res.list.reduce(
				(prev, cur) => {
					if (
						cur.informationProlect == 4 &&
						cur.informationCategory == 2
					) {
						prev.push({
							value: cur.informationContent,
						});
					}
					return prev;
				},
				[]
			);
			if (!this.ruleForm.creditFocusIndicators.length) {
				this.ruleForm.creditFocusIndicators.push({});
			}
			this.ruleForm.otherFocusIndicators = res.list.reduce(
				(prev, cur) => {
					if (
						cur.informationProlect == 4 &&
						cur.informationCategory == 3
					) {
						prev.push({
							value: cur.informationContent,
						});
					}
					return prev;
				},
				[]
			);
			if (!this.ruleForm.otherFocusIndicators.length) {
				this.ruleForm.otherFocusIndicators.push({});
			}
			this.ruleForm.basicRiskControlMeasures = res.list.reduce(
				(prev, cur) => {
					if (
						cur.informationProlect == 5 &&
						cur.informationCategory == 1
					) {
						prev.push({
							value: cur.informationContent,
						});
					}
					return prev;
				},
				[]
			);
			if (!this.ruleForm.basicRiskControlMeasures.length) {
				this.ruleForm.basicRiskControlMeasures.push({});
			}
			this.ruleForm.commonRiskControlMeasures = res.list.reduce(
				(prev, cur) => {
					if (
						cur.informationProlect == 5 &&
						cur.informationCategory == 2
					) {
						prev.push({
							value: cur.informationContent,
						});
					}
					return prev;
				},
				[]
			);
			if (!this.ruleForm.commonRiskControlMeasures.length) {
				this.ruleForm.commonRiskControlMeasures.push({});
			}
			this.ruleForm.focusRiskPoints = res.list.reduce((prev, cur) => {
				if (
					cur.informationProlect == 5 &&
					cur.informationCategory == 3
				) {
					prev.push({
						value: cur.informationContent,
					});
				}
				return prev;
			}, []);
			if (!this.ruleForm.focusRiskPoints.length) {
				this.ruleForm.focusRiskPoints.push({});
			}
			this.ruleForm.maiAdvantages = res.list.reduce((prev, cur) => {
				if (
					cur.informationProlect == 6 &&
					cur.informationCategory == 1
				) {
					prev.push({
						value: cur.informationContent,
					});
				}
				return prev;
			}, []);
			if (!this.ruleForm.maiAdvantages.length) {
				this.ruleForm.maiAdvantages.push({});
			}
			this.ruleForm.mainDisadvantages = res.list.reduce((prev, cur) => {
				if (
					cur.informationProlect == 6 &&
					cur.informationCategory == 2
				) {
					prev.push({
						value: cur.informationContent,
					});
				}
				return prev;
			}, []);
			if (!this.ruleForm.mainDisadvantages.length) {
				this.ruleForm.mainDisadvantages.push({});
			}
			this.ruleForm.suitableCustomers = res.list.reduce((prev, cur) => {
				if (
					cur.informationProlect == 7 &&
					cur.informationCategory == 1
				) {
					prev.push({
						value: cur.informationContent,
					});
				}
				return prev;
			}, []);
			if (!this.ruleForm.suitableCustomers.length) {
				this.ruleForm.suitableCustomers.push({});
			}
			this.ruleForms = Object.assign({}, this.ruleForm, this.dataObj);
			console.log(JSON.stringify(this.ruleForm));
		},
	},
	computed: {
		drawer_: {
			get() {
				return this.examineVisible;
			},
			set(v) {
				this.$emit('update:examineVisible', v);
			},
		},
		filterMortgageFocusIndicators() {
			if (this.ruleForm.mortgageOrCredit == 1) {
				return this.ruleForm.mortgageFocusIndicators;
			} else {
				return [];
			}
		},
	},
	created() {
		this.mobileInfo && this.open()
	}
};
</script>
<style lang="scss" scoped>
.mb {
	margin-bottom: 0px;
}

.box-card {
	margin-bottom: 10px;
}

.demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 30px 0;
}

.demo-drawer__footer button {
	width: 20% !important;
	margin: 0 10px;
}

.el-date-editor {
	width: 100%;
}

.edit {
	p {
		font-weight: 800;
		margin-bottom: 20px;
	}

	span {
		text-indent: 40px;
		display: block;
		margin-bottom: 10px;
		font-weight: 800;
	}
}

.requirement {
	display: flex;
	align-items: center;
	padding-left: 60px;
	margin: 20px 0;

	img {
		width: 20px !important;
		margin-right: 10px;
	}
}
</style>
